<template>
  <main>
    <PageTitle>Kundenportal</PageTitle>

    <iframe
      :src="portalURL"
      @mouseover="deactivateCursor"
      @mouseout="activateCursor"
    ></iframe>
  </main>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import { incrementComponentsLoading, updateCursor } from "@/events";

export default {
  components: { PageTitle },

  data: () => {
    return {
      portalURL: process.env.VUE_APP_CLIENT_PORTAL_URL
    };
  },

  methods: {
    deactivateCursor() {
      this.$emit(updateCursor, { cursor: "none" });
    },
    activateCursor() {
      this.$emit(updateCursor, { cursor: "default" });
    }
  },

  beforeRouteLeave(to, from, leave) {
    this.activateCursor();
    this.$emit(incrementComponentsLoading);
    leave();
  }
};
</script>

<style scoped>
iframe {
  width: 100%;
  grid-column: 1 / -1;
  border: none;
  height: 100vh;
}
</style>
